<div class="container" style="max-width:800px">
    <div class="row">
        <h2 class="mx-auto" style="border-bottom:1px solid grey;text-align:center">Our E-Retail Partners</h2>
    </div>
    <div class="row" style="margin-bottom:25px">
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.overstock.com/search?refinebrand=Blazing+Needles&searchtype=Header" target="_blank">
                    <img class="img-fluid"src="assets/ourpartners/overstock.jpg" style="max-height:200px">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.wayfair.com/Blazing-Needles-B2953.html" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/wayfair.jpg" style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.cymax.com/blazing-needles--C0.htm?q=blazing-needles" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/cymax.jpg"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a   href="http://search.hayneedle.com/search/index.cfm?Ntt=blazing%20needles" target="_blank">
                <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/hayneedle.jpg" style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.amazon.com/Blazing-Needles/b/ref=bl_dp_s_web_3019215011?ie=UTF8&node=3019215011&field-lbr_brands_browse-bin=Blazing+Needles"  target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/amazon-com-logo.jpg"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="https://www.lowes.com/pl/Blazing-needles--Home-decor/4294935484?refinement=4294691686" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/lowes.png"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
    </div>
</div>