<div class="container-fluid" style="margin:auto;max-width:1080px">
    <div class="row">
        <blockquote>
        <h3>At Blazing Needles, L.P., we are dedicated to quick and accurate shipping of soft, stylish home textiles to our eCommerce partners' customers' doors and to our wholesale partners' stores. Furthermore, we are committed to quality in our manufacturing processes, professionalism in our business dealings, and courtesy in our customer relations.  <img src="assets/favicon-32x32.png" style="display:inline;vertical-align:middle" width="20" height="20" alt=""/></h3>
  		<footer>Blazing Needles Management</footer>
		</blockquote>
    </div>
    <div class="row">
        <img class="img-fluid pull-left col-sm-6 col-xs-12" style="padding-right:1em;float:left;clear:right;" src="assets/Beach Mills Aerial View_LR_EDIT.jpg">
        <div>
            <h2 style="border-bottom:1px solid grey;padding:5px;margin-top:5px;">Who We Are</h2>
            <p>Blazing Needles, L.P. is a manufacturer and importer of home textiles and decor, specializing in eCommerce drop-shipping and traditional wholesale.</p>
            <p>We many years of experience in both the domestic and imported textile industries, with material and product suppliers stretching across the globe, from India to China to the United States.  Our production and manufacturing departments include designers with years of experience in the production of textile products.  Our shipping department has years of experience in providing accurate LTL and Fedex/UPS drop-ship parcel shipping service on behalf of our many wholesale and eRetail partners. We have a well-earned reputation in the domestic home textiles market as a wholesaler/drop-shipper operating with the utmost in honesty, quality, and efficacy, with a long track record of trusting relationship with our suppliers and customers.</p>
            <p>We offer over 300 different designs and styles of soft home furnishings in a wide variety of stunning, high-quality indoor and outdoor fabrics. Our catalog features products from across the market spectrum of home textiles, including chair cushions, futon mattresses, futon covers, pillows, curtains, rugs, and other custom designed textile merchandise.  We also carry a wide variety of fabric materials that can be used for a variety of large-scale wholesale orders.</p>
            <p>While our products are currently only available for wholesale or third-party eCommerce drop-ship purchase, our products are readily available for purchase online with one of our many eRetail and wholesale partners, including Overstock, Wayfair, Hayneedle, Target, Lowes, and At Home.</p>
            <h2 style="border-bottom:1px solid grey;padding:5px;">Our Story</h2>
            <p>Blazing Needles, L.P. began as an affiliate company to International Caravan, Inc.  Its first manufactured products were papasan cushions for papasans imported by International Caravan in the late 1970s.  As time progressed, Blazing Needles began to manufacture more and more items for International Caravan’s furniture collection, adding a much needed bit of customizability and comfort to a wide variety of products.</p>
            <p>In the early 1990s, Blazing Needles began to manufacture a wide variety of futons, and over time established itself as one of the more prolific futon mattress manufacturers in the United States.  It was also around this time that Blazing Needles began to do larger, bulk contract orders for wholesale customers and third-party cut-and-sew projects alike.</p>
            <p>In the 2000s, following the example of its affiliate, International Caravan, Blazing Needles began to delve into the fast-growing internet drop-ship parcel shipping business.  By 2010, this new world of internet retail became the primary pillar of growth for the company, its products available for sale on many of the largest e-retailers on the web.</p>
            <p>Today, Blazing Needles has developed into one of the largest and most visible home textile brands in the eCommerce marketplace, with a sizable product footprint in many of the most important home textiles categories and on many of the largest e-retails marketplaces on the web.</p>
        </div>
    </div> 
</div>