<div class="container"  style="max-width:960">
    <div class="row my-2">
            <h1 style="text-align:center;margin:auto">Fabric Collections</h1>
    </div>
<div id="accordion">
    <div *ngFor="let fabricType of fabricTypes  | orderBy: 'Fabric_Types'">
    <div class="card" style="margin-bottom: 1rem;">
      <div class="card-header" id="heading{{fabricType.Fabric_Types}}">
        <h5 class="mb-0">
          {{fabricType.Fabric_Types}}
          <button class="btn btn-light" data-toggle="collapse"  (click)="fabricType.Show = !fabricType.Show" data-target="#{{fabricType.Fabric_Types}}" aria-expanded="true" aria-controls="{{fabricType.Fabric_Types}}">
            Show variations
          </button>
            <button class="btn btn-link" aria-expanded="true">
              <a href="#/browseproducts/byfabrictype/{{fabricType.Fabric_Types.split(' ').join('_')}}/{{fabricType.Fabric_Types_ID}}">
              Browse products that include this fabric
              </a>
            </button>
        </h5>
        <p>Fabric descriptions coming soon...</p>
      </div>
      <div id="{{fabricType.Fabric_Types}}" class="collapse " [ngClass]="{'show': fabricType.Show}" aria-labelledby="heading{{fabricType.Fabric_Types}}" data-parent="#accordion">
            <div class="card-body">
              <div class="row card-columns  mx-auto">
                <div class="card-deck">
                    <ng-container *ngFor="let fabricoption of fabricOptions  | orderBy: ['Fabric_Types_ID','Fabric_Option_Code']"> 
                        <div class="col-4 col-sm-3 col-md-3 col-lg-2 px-0"  *ngIf="fabricoption.Fabric_Types_ID == fabricType.Fabric_Types_ID">
                            <div  class="card" class="grow">
                                <a class="link-unstyled" >
                                    <img class="card-img-top img-fluid" src="{{fabricoption.Host_Thumbnail_Swatch_Photo_Path}}" onerror="this.src='assets/productimagenotavailable.png'">
                                    <div class="card-body">
                                        <h3>{{fabricoption.Fabric_Option_Name}}</h3>
                                        <h4>{{fabricoption.Fabric_Option_Code}}</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
  </div>
</div>