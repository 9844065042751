<div class="container mx-auto">
    <div class="row" style="margin:auto">
        <div class="col-12 col-xl-12 " style="margin:auto">
            <img src="assets/BNHomeBanner.jpg" class="img-fluid" alt="" style="margin:auto"/>
        </div>
    </div>
    <div class="container">
      <div class="row">
          <h2 class="col-md-12" style="text-align:center; margin: 1rem">Browse by Category</h2>
      </div>
  </div>
  <div class="container">
      <div class="row" style="max-width:60rem; margin:auto ">
          <nav>
          <ul class="nav nav-pills nav-fill mx-auto mb-3" style="margin-bottom:0.625rem;"  role="tablist">
            <li class="nav-item col-xs-6 col-sm-6 " style="height:100%"><a class="nav-link home-nav-item" [ngClass]='{"active":activetog==1}' (click)="activeCategoryGroup = 'Outdoor';activetog=1" >Outdoor Categories</a></li>
            <li class="nav-item col-xs-6 col-sm-6" style="height:100%"><a class="nav-link home-nav-item"  [ngClass]='{"active":activetog==2}' (click)="activeCategoryGroup = 'Indoor';activetog=2" >Indoor Categories</a></li>
          </ul>
          <div class="card-deck" *ngIf="activeCategoryGroup === 'Outdoor'" >
              <div class="col-xs-6 col-sm-3 px-0" *ngFor="let category of outdoorCategories | orderBy: 'Product_Category'"> 
                  <div class="card" class="grow" >
                      <a class="link-unstyled" href="#/browseproducts/bycategory/{{category.indoor_outdoor}}/{{category.product_category.split(' ').join('_')}}/{{category.product_category_id}}">
                          <img class="card-img-top img-fluid" src="{{category.top_seller_photo_path}}" onerror="this.src='assets/productimagenotavailable.png'">
                          <div class="card-body">
                              <h4 class="card-title">{{category.product_category}}</h4>
                          </div>
                      </a>
                    </div>
              </div>
          </div>
          <div class="card-deck" *ngIf="activeCategoryGroup === 'Indoor'" >
              <div class="col-xs-6 col-sm-3 px-0" *ngFor="let category of indoorCategories | orderBy: 'Product_Category'"> 
                  <div class="card" class="grow" >
                      <a class="link-unstyled" href="#/browseproducts/bycategory/{{category.indoor_outdoor}}/{{category.product_category.split(' ').join('_')}}/{{category.product_category_id}}">
                          <img class="card-img-top img-fluid" src="{{category.top_seller_photo_path}}" onerror="this.src='assets/productimagenotavailable.png'">
                          <div class="card-body">
                              <h4 class="card-title">{{category.product_category}}</h4>
                          </div>
                      </a>
                    </div>
              </div>
          </div>
      </nav>
      </div>
  </div>
</div>

    <!--<div class="row">
        <div class="col-md-6">
                <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carousel-example-generic" data-slide-to="0"></li>
                <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                <li data-target="#carousel-example-generic" data-slide-to="2"></li>
              </ol>
    
              <div class="carousel-inner">
                <div class="item active">
                  <img src="./images/homecarousel/slide1.jpg" alt="...">
                  <div class="carousel-caption">
                      <h4>
                       We offer a wide variety of soft home textile products ...
                       </h4>
                  </div>
                </div>
                <div class="item">
                  <img src="./images/homecarousel/slide2.jpg" alt="...">
                  <div class="carousel-caption">
                  <p>
                   We offer a wide variety of indoor furniture that add touches of class and style to your home furnishings...</p>
                  </div>
                </div>
                <div class="item">
                  <a href="#/ourpartners"><img src="./images/homecarousel/slide3.jpg" alt="..."></a>
                  <div class="carousel-caption">
                   Our products are available for retail purchase on some of the largest web retailers in the United States...
                  </div>
                </div>
              </div>
    
              <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            </div>
        <div class="col-md-6">
            <h1 style="margin-bottom:30px">Welcome to Blazing Needles, your source for !</h1>
            <h2><i>A world of quality home furnishings at your fingertips...</i></h2>
            
        </div>
    </div>-->