<div class="container mx-auto" style="max-width:1200px">
    <div class="row  mx-auto">
        <h3 class="mx-auto" style="margin:1rem">{{browseproductsheader}}</h3>
    </div>
    <div class="row d-flex mx-auto justify-content-center">
        <div class="d-flex justify-content-center">
            <h5>Total items: {{browseProducts.length}}</h5>
        </div>
        <div class="d-flex justify-content-center" >
            <pagination-controls (pageChange)="page = $event" maxSize="6" autoHide = "true" ></pagination-controls>
        </div>
    </div>
    <div class="row card-columns  mx-auto">
        <div class="card-deck">
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 px-0" *ngFor="let product of browseProducts  | orderBy: 'Master_SKU' | paginate: { itemsPerPage: 24, currentPage: page }"> 
                <div class="card" class="grow" >
                    <a class ="link-unstyled" href='#/browseproducts/productdetail/{{product.Top_Seller_Option_SKU_ID}}'>
                        <img class="card-img-top img-fluid" src="{{product.Host_Thumbnail_Photo_Path}}" onerror="this.src='assets/productimagenotavailable.png'">
                        <div class="card-body">
                            <h4 class="card-title">{{product.Master_SKU}}</h4>
                            <p class="card-text">{{product.Public_Master_Description}}</p>
                        </div>
                    </a>
                </div>
        </div>
    </div>
    <div class="row mx-auto">
        <div style="text-align:center;margin:auto">
            <pagination-controls (pageChange)="page = $event" maxSize="6" autoHide = "true" ></pagination-controls>
        </div>
    </div>
</div>