import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CategoryService } from '../../../services/category.service';
import { ProductCategory } from '../../../classes/productcategory';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  allCategories: ProductCategory[];  
  indoorCategories: ProductCategory[];  
  outdoorCategories: ProductCategory[];  
  activeCategoryGroup:string;
  activetog: number;
  category:ProductCategory;


  constructor(private route: ActivatedRoute,
    private categoryService: CategoryService,
    private location: Location) {
    }

  ngOnInit(): void {
    this.activeCategoryGroup = 'Outdoor';
    this.activetog = 1;
    this.getIndoorCategories()
    this.getOutdoorCategories()
  }

  getAllCategories(): void {
    this.categoryService.getAllCategories()
      .subscribe(Categories => this.allCategories = Categories);
  }

  getIndoorCategories(): void {
    this.categoryService.getCategoriesbyIndoorOutdoor('Indoor')
      .subscribe(Categories => this.indoorCategories = Categories);
  }

  getOutdoorCategories(): void {
    this.categoryService.getCategoriesbyIndoorOutdoor('Outdoor')
      .subscribe(Categories => this.outdoorCategories = Categories);
  }

}
