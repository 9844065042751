export class ProductMaster {
    Master_SKU_ID:number;
    Master_SKU:string;
    Public_Master_Description:string;
    Product_Category:string;
    Fabric_Types:string;
    Fill_Type:string;
    Indoor_Outdoor:string;
    Net_Price:number;
    Fabric_Types_ID:number;
    Product_Category_ID:number;
    Design_ID:number;
    Design_Number:string;
    Design_Description:string;
    Host_Thumbnail_Photo_Path:string;
    Top_Seller_Option_SKU_ID:number;
    Inactive_Master:number;
    Closure_Type:string;
    Edging:string;
    Fastening_Type:string;
    SKU_Search_String:string;
}