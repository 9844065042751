<div  ng-controller="ContactUsController" class="container" style="margin:auto;max-width:900px">
    <div class="row">
        <h2>Interested in selling Blazing Needles products on your website or in your brick and mortar store?</h2>
    </div>
      <form>
          <div class="row">
      <div class="form-group col-sm-6">
        <label for="contactcompanyname">Company Name</label>
        <input type="text" class="form-control" id="contactcompanyname" placeholder="Company Name" ng-model="user.company">
      </div>
      <div class="form-group col-sm-6">
        <label for="contactweburl">Website URL</label>
        <input type="text" class="form-control" id="contactweburl" placeholder="Website URL" ng-model="user.websiteurl">
      </div>
      <div class="form-group col-sm-6">
        <label for="contactfirstname">First Name</label>
        <input type="text" class="form-control" id="contactfirstname" placeholder="First Name" ng-model="user.firstname">
      </div>
      <div class="form-group col-sm-6">
        <label for="contactlastname">Last Name</label>
        <input type="text" class="form-control" id="contactlastname" placeholder="Last Name" ng-model="user.lastname">
      </div>
      <div class="form-group col-sm-6">
        <label for="contactemail">Email</label>
        <input type="text" class="form-control" id="contactemail" placeholder="Email" ng-model="user.email">
      </div>
      <div class="form-group col-sm-6">
        <label for="contactphonenumber col-sm-6">Phone Number</label>
        <input type="tel" class="form-control" id="contactphonenumber" placeholder="Phone Number" ng-model="user.phonenumber">
      </div>
      <div class="form-group col-sm-12">
        <label for="contactabout">Tell us about your company...</label>
        <textarea type="text" class="form-control" id="contactabout" placeholder="Tell us about your company..." ng-model="user.companydescription"></textarea>
      </div>
        <div class="form-group col-sm-12">
        <label for="contactinquiry">What sorts of products from our catalog are you interested in selling?</label>
        <textarea type="text" class="form-control" id="contactinquiry" placeholder="What sorts of products from our catalog are you interested in selling?" ng-model="user.productinquiry"></textarea>
      </div>
          </div>
      <div class="row" style="text-align:center;margin-bottom:20px;">
      <button type="submit" class="btn btn-default" ng-click="submitcontactus(); formSubmitted = 1">Submit</button>
      </div>
    </form>
      <div ng-show="formSubmitted">
      	<h3>Thanks for your inquiry! We will get back to you as soon as we can.</h3>
      </div>
</div>
