<div class="container" style="margin: auto; margin-top: 1rem;">
    <div class="row d-block">
        <div class="col-md-6 col-sm-10 col-10 mx-auto float-md-left">
            <div>
                <img src="{{currentImage}}"  onerror="this.src='assets/productimagenotavailable.png'" class="img-fluid" alt="Image not available...">
            </div>
            <div *ngIf=" numoptionphotos > 1 " style="overflow:auto;white-space:nowrap;height:8.125rem;margin:0rem;padding:0rem">
                <div [ngStyle]="{ width:optionPhotosBarWidth}">
                    <div *ngFor="let photo of productphotos">
                        <div style="max-height:6.25rem;max-width:6.25rem;margin:0.3125rem 0 0 0.3125rem;display:table-cell;float:left">
                            <img src="{{photo.Host_Thumbnail_Photo_Path}}"  onerror="this.src='assets/productimagenotavailable.png'" class="img-fluid" (click)="setNewImage(photo.Host_Web_Size_Photo_Path);" alt="Image not available...">
                        </div>
                    </div>
                </div>
           </div>   
        </div>
        <div class="col-md-6 col-sm-12  float-md-right ">
            <h3>{{productdetail.Option_Description}}</h3>
            <h5 style="float:left;clear:both"> Item #: {{productdetail.Option_SKU}}</h5>
           <!-- <h4 *ngIf="productdetail.Out_of_Stock == 1" style="float:right">Out of Stock</h4>
            <h4 *ngIf="productdetail.Out_of_Stock == 0" style="float:right">In Stock</h4> -->
        </div>
        <div class="col-sm-12 col-md-6 float-md-right">
            <h5 style="clear:both">{{productdetail.Copy_Paragraph}}</h5>
        </div>
        <div class="col-md-12 col-lg-6 float-md-right justify-content-center" style="margin-top:1rem"> 
            <div *ngIf=" numproductoptions > 1 " >
                <h4>Available in these color options:</h4>
                <div style="overflow:auto;white-space:nowrap;height:180px;">
                    <div [ngStyle]="{ width:productOptionBarWidth}">
                        <div *ngFor="let option of productoptions | orderBy: 'Fabric_Option_Name'">
                            <div style="height:6.25rem;width:6.25rem;margin:0.625rem;display:table-cell;float:left">
                                <a (click)="ngOnInit()" href="#/browseproducts/productdetail/{{option.Option_SKU_ID}}">
                                    <img src="{{option.Host_Thumbnail_Photo_Path}}"  onerror="this.src='assets/productimagenotavailable.png'" class="img-fluid" alt="Image not available...">
                                        <p style="text-align:center;white-space: normal;">{{option.Fabric_Option_Name}}</p>
                                </a>  
                            </div>
                        </div>
                    </div>
               </div>   
           </div> 
        </div> 
           <script
        type="text/javascript"
        async defer
        src="//assets.pinterest.com/js/pinit.js"
        data-pin-error 
    ></script>
           <a data-pin-do="buttonPin" href="https://www.pinterest.com/pin/create/button/?media={{currentImagePinPath}}&description={{productdetail.Option_Description}}"></a>   
        </div>
    <div class="row container">
        <div class="col-md-6 col-sm-12">
            <div style="margin:1rem">
                <h4 style="padding-bottom:0.25rem;border-bottom:solid 0.0625rem #D3D3D3">&nbsp;&nbsp;Product Description:</h4>
            </div>
            <div style="margin:1rem">
                <p>{{productdetail.Copy_Paragraph}}</p>
                <div style="margin-left:1rem"><span [innerHtml]="description | safeHtml"></span></div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div style="margin:1rem">
                <h4 style="padding-bottom:0.25rem;border-bottom:solid 0.0625rem #D3D3D3">&nbsp;&nbsp;Product Specifications:</h4>
            </div>
            <div  style="margin:1rem">
                <p *ngIf="productdetail.Product_Category && productdetail.Indoor_Outdoor">Category: <a ng-href="#/categories/{{productdetail.Indoor_Outdoor}}/{{productdetail.Product_Category_ID}}/{{productdetail.Product_Category}}">{{productdetail.Indoor_Outdoor}} {{productdetail.Product_Category}}</a></p>
                <p *ngIf="productdetail.Fabric_Types && productdetail.Fabric_Types_ID">Fabric Type: <a ng-href="#/fabriccollections/{{productdetail.Fabric_Types}}/{{productdetail.Fabric_Types_ID}}">{{productdetail.Fabric_Types}}</a></p>
                <p *ngIf="productdetail.Design_ID && productdetail.Design_Number">Design: <a ng-href="#/designs/{{productdetail.Design_ID}}/{{productdetail.Design_Number}}">{{productdetail.Design_Number}}</a></p>
                <p *ngIf="productdetail.Fastening_Type">Fastening Type: {{productdetail.Fastening_Type}}</p>
                <p *ngIf="productdetail.Edging">Edging: {{productdetail.Edging}}</p>
                <p *ngIf="productdetail.Closure_Type">Closure Type: {{productdetail.Closure_Type}}</p>
                <p *ngIf="productdetail.Insert_Included==1">Insert Included</p>
                <p *ngIf="productdetail.Fill_Type">Closure Type: {{productdetail.Fill_Type}}</p>
            </div>
        </div>
    </div>
    </div>