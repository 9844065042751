export class ProductOption {
    Master_SKU_ID:number;
    Option_SKU_ID:number;
    Option_SKU:string;
    Out_of_Stock:number;
    Vacuum_Packed:number;
    Option_Description:string;
    Fabric_Option_Name:string;
    Fabric_Option_Code:string;
    Fabric_Option_ID:number;
    Fabric_Types_ID:number;
    Fabric_Types:string;
    Fabric_Materials:string;
    Fill_Type:string;
    Fastening_Type:string;
    Indoor_Outdoor:string;
    Product_Category_ID:number;
    Product_Category:string;
    Copy_Paragraph:string;
    Not_Sellable:number;
    Assembly_Item:number;
    HTML_Includes:string;
    HMTL_Options:string;
    Edging:string;
    Closure_Type:string;
    Insert_Included:number;
    HTML_Dimensions:string;
    Design_ID:number;
    Design_Number:string;
    Design_Description:string;
    Host_Full_Size_Photo_Path:string;
    Host_Thumbnail_Photo_Path:string;
    Host_Web_Size_Photo_Path:string;
    Host_Thumbnail_Swatch_Photo_Path:string;
}