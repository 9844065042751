import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProductOption } from '../classes/productoption';
import { ProductMaster } from '../classes/productmaster';
import { ProductPhoto } from '../classes/productphoto';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  ProductDetail: ProductOption;
  BrowseProducts: ProductMaster[]; 
  productOptions: ProductOption[]; 
  productphotos: ProductPhoto[];
  baseUrl = 'https://www.blazingneedleslp.com/apibn';


  constructor(private http: HttpClient) { }

  getMasterProductsBySearchString(searchterms:string): Observable<ProductMaster[]> {
    return this.http.get(this.baseUrl+'/getMasterProductsBySearchString.php?searchterms='+searchterms).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError));
  }

  getMasterProductsByCategoryandIndoorOutdoor(categoryid:number,indooroutdoor:string): Observable<ProductMaster[]> {
    return this.http.get(this.baseUrl+'/getMasterProductsByCategoryandIndoorOutdoor.php?categoryid='+categoryid+'&indooroutdoor='+indooroutdoor).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError));
  }

  getOptionProductDetailsbyID(optionskuid:number): Observable<ProductOption> {
    return this.http.get(this.baseUrl+'/getOptionProductDetailsbyID.php?optionskuid=' + optionskuid).pipe(
      map((res) => {
        this.ProductDetail = res['data'][0];
        console.log(this.ProductDetail);
        return this.ProductDetail;
      }),
      catchError(this.handleError)); 
  }

  getMasterProductsByNewArrivals(): Observable<ProductMaster[]> {
    return this.http.get(this.baseUrl+'/getMasterProductsByNewArrivals.php').pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError)); 
  }

  getProductOptionPhotosbyOptionSKUID(optionskuid:number): Observable<ProductPhoto[]> {
    return this.http.get(this.baseUrl+'/getProductOptionPhotosbyOptionSKUID.php?optionskuid='+optionskuid).pipe(
      map((res) => {
        this.productphotos = res['data'];
        console.log(this.productphotos);
        return this.productphotos;
      }),
      catchError(this.handleError)); 
  }

  getProductOptionsbyMasterSKUID(masterskuid:number): Observable<ProductOption[]> {
    return this.http.get(this.baseUrl+'/getProductOptionsbyMasterSKUID.php?masterskuid='+masterskuid).pipe(
      map((res) => {
        this.productOptions = res['data'];
        console.log(this.productOptions);
        return this.productOptions;
      }),
      catchError(this.handleError)); 
  }

  getMasterProductsByFabricTypeID(fabrictypeid:number): Observable<ProductMaster[]> {
    return this.http.get(this.baseUrl+'/getMasterProductsByFabricTypeID.php?fabrictypeid='+fabrictypeid).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError)); 
  }


  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }



}
