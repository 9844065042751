import { Injectable } from '@angular/core';
import { FabricType } from '../classes/fabrictype';
import { FabricOption } from '../classes/fabricoption';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FabricService {

  fabricTypes:FabricType[]; 
  fabricOptions:FabricOption[];
  baseUrl = 'https://www.blazingneedleslp.com/apibn';

  constructor(private http: HttpClient) { }

  getAllFabricTypes(): Observable<FabricType[]> {
    return this.http.get(this.baseUrl+'/getAllFabricTypes.php').pipe(
      map((res) => {
        this.fabricTypes = res['data'];
        return this.fabricTypes;
      }),
      catchError(this.handleError)); 
  }

  getAllFabricOptions(): Observable<FabricOption[]> {
    return this.http.get(this.baseUrl+'/getAllFabricOptions.php').pipe(
      map((res) => {
        this.fabricOptions = res['data'];
        return this.fabricOptions;
      }),
      catchError(this.handleError)); 
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
}
