import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProductCategory } from '../classes/productcategory';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  Categories: ProductCategory[];  
  baseUrl = 'https://www.blazingneedleslp.com/apibn';
  constructor(private http: HttpClient) { }

  getAllCategories(): Observable<ProductCategory[]> {
    return this.http.get(this.baseUrl+'/getAllProductCategories.php').pipe(
      map((res) => {
        this.Categories = res['data'];
        console.log(this.Categories);
        return this.Categories;
      }),
      catchError(this.handleError));
  }

  getCategoriesbyIndoorOutdoor(indooroutdoor:string): Observable<ProductCategory[]> {
    return this.http.get(this.baseUrl+'/getCategoriesbyIndoorOutdoor.php?indooroutdoor='+indooroutdoor).pipe(
      map((res) => {
        this.Categories = res['data'];
        console.log(this.Categories);
        return this.Categories;
      }),
      catchError(this.handleError));
  }

  
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }

}
