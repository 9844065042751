import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';

import { AppComponent } from './app.component';
import { AboutusComponent } from './components/views/aboutus/aboutus.component';
import { BrowseproductsComponent } from './components/views/browseproducts/browseproducts.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/views/home/home.component';
import { ContactusComponent } from './components/views/contactus/contactus.component';
import { OurpartnersComponent } from './components/views/ourpartners/ourpartners.component';
import { OurstaffComponent } from './components/views/ourstaff/ourstaff.component';
import { ProductdetailsComponent } from './components/views/productdetails/productdetails.component';
import { CategoriesComponent } from './components/views/categories/categories.component';
import { FabricsComponent } from './components/views/fabrics/fabrics.component';
import { Safe } from './domsanitize.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AboutusComponent,
    BrowseproductsComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactusComponent,
    OurpartnersComponent,
    OurstaffComponent,
    ProductdetailsComponent,
    CategoriesComponent,
    FabricsComponent,
    Safe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    Ng2SearchPipeModule, 
    AppRoutingModule,
    OrderModule,
    NgbModule,
    NgxPaginationModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
