import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FabricOption } from '../../../classes/fabricoption';
import { FabricType } from '../../../classes/fabrictype';
import { FabricService } from '../../../services/fabric.service';

@Component({
  selector: 'app-fabrics',
  templateUrl: './fabrics.component.html',
  styleUrls: ['./fabrics.component.css']
})
export class FabricsComponent implements OnInit {

  currentFabricType: FabricType;
  currentFabricOption: FabricOption;
  fabricTypes: FabricType[];  
  fabricOptions: FabricOption[];  
  indooroutdoor: string;
  fabriccollectionheader: string;
  sub:any;
  group:string;

  constructor(private route: ActivatedRoute,
    private fabricService: FabricService,
    private location: Location) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {this.group = params['indooroutdoor'];
    console.log(this.group);
      if (this.group) {
    } else {
        this.getAllFabricTypes()
        this.getAllFabricOptions()
        this.fabriccollectionheader = "Fabric Collections"
    }
  });
  }

  getAllFabricTypes(): void {
  this.fabricService.getAllFabricTypes()
    .subscribe(fabricTypes => this.fabricTypes = fabricTypes);
  }

  getAllFabricOptions(): void {
  this.fabricService.getAllFabricOptions()
    .subscribe(fabricOptions => this.fabricOptions = fabricOptions);
} 

goBack(): void {
  this.location.back();
}
}
