import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/views/home/home.component';
import { ContactusComponent } from './components/views/contactus/contactus.component';
import { OurstaffComponent } from './components/views/ourstaff/ourstaff.component';
import { OurpartnersComponent } from './components/views/ourpartners/ourpartners.component';
import { CategoriesComponent } from './components/views/categories/categories.component';
import { BrowseproductsComponent } from './components/views/browseproducts/browseproducts.component';
import { AboutusComponent } from './components/views/aboutus/aboutus.component';
import { ProductdetailsComponent } from './components/views/productdetails/productdetails.component';
import { FabricsComponent } from './components/views/fabrics/fabrics.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "home"},
  {path: "home", component: HomeComponent},
  {path: "aboutus", component: AboutusComponent},
  {path: "ourstaff", component: OurstaffComponent},
  {path: "ourpartners", component: OurpartnersComponent},
  {path: "contactus", component: ContactusComponent},  
  {path: "categories/:indooroutdoor", component: CategoriesComponent},
  {path: "fabriccollections", component: FabricsComponent},
  {path: "fabriccollections/:indooroutdoor", component: FabricsComponent},
  {path: "browseproducts", component: BrowseproductsComponent}, 
  {path: "browseproducts/bycollection/:collectionname/:collectionid", component: BrowseproductsComponent},  
  {path: "browseproducts/bycategory/:indooroutdoor/:categoryname/:categoryid", component: BrowseproductsComponent},
  {path: "browseproducts/byfabrictype/:fabrictype/:fabrictypeid", component: BrowseproductsComponent},
  {path: "browseproducts/byfabricoption/:fabricoptionname/:fabricoptionid", component: BrowseproductsComponent},
  {path: "browseproducts/bydesign/:designid", component: BrowseproductsComponent},
  {path: "browseproducts/search/:searchstring", component: BrowseproductsComponent},
  {path: "browseproducts/newarrivals/:newarrivals", component: BrowseproductsComponent},
  {path: "browseproducts/productdetail/:optionskuid", component: ProductdetailsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
