<div class="container mx-auto">
    <div class="row">
        <h1 class="col-12 mx-auto" style="text-align:center; margin:1rem">{{categorygroupheader}}</h1>
    </div>
    <div class="row">
        <div class="card-deck">
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 px-0" *ngFor="let category of currentBrowseCategories  | orderBy: 'product_category' "> 
                <div class="card" class="thumbnail grow" >
                    <a class="link-unstyled" href="#/browseproducts/bycategory/{{category.indoor_outdoor}}/{{category.product_category.split(' ').join('_')}}/{{category.product_category_id}}">
                        <img class="card-img-top img-fluid" src="{{category.top_seller_photo_path}}" onerror="this.src='assets/productimagenotavailable.png'">
                        <div class="card-body">
                            <h4 class="card-title">{{category.product_category}}</h4>
                        </div>
                    </a>
                  </div>
            </div>
        </div>
    </div>
</div>