import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProductCategory } from '../../../classes/productcategory';
import { CategoryService } from '../../../services/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  currentCategory: ProductCategory;
  currentBrowseCategories: ProductCategory[];  
  indooroutdoor: string;
  categorygroupheader: string;
  sub:any;
  group:string;
  constructor(private route: ActivatedRoute,
    private categoryService: CategoryService,
    private location: Location) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {this.group = params['indooroutdoor'];
    console.log(this.group);
      if (this.group) {
        this.getCollectionsByIndoorOutdoor(this.group);
        this.categorygroupheader = this.group + " Product Categories"
    } else {
        this.getAllCategories();
        this.categorygroupheader = "Product Categories"
    }
  });
  }

  getAllCategories(): void {
  this.categoryService.getAllCategories()
    .subscribe(currentBrowseCategories => this.currentBrowseCategories = currentBrowseCategories);
}

getCollectionsByIndoorOutdoor(indooroutdoor:string): void {
  this.categoryService.getCategoriesbyIndoorOutdoor(indooroutdoor)
    .subscribe(currentBrowseCategories => this.currentBrowseCategories = currentBrowseCategories);
} 

goBack(): void {
  this.location.back();
}

}
