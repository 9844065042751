import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProductService } from '../../../services/product.service';
import { ProductOption } from '../../../classes/productoption';
import { ProductMaster } from '../../../classes/productmaster';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-browseproducts',
  templateUrl: './browseproducts.component.html',
  styleUrls: ['./browseproducts.component.css']
})
export class BrowseproductsComponent implements OnInit {

  sub:any;
  browseProducts: ProductMaster[];
  browseproductsheader:string;
  indooroutdoor:string;
  searchstring:string;
  collectionid:number;
  collectionname:string;
  categoryid:number;
  categoryname:string;
  newarrivals:string;
  page = 1;
  product: ProductMaster;
  collectionSize:number;
  pageSize:number;
  maxSize:number ;
  rotate:string ;
  boundaryLinks:string;
  fabrictypeid:number;
  fabrictype:string;

  constructor(private router: Router,private route: ActivatedRoute,
    private productService: ProductService,
    private location: Location) { 

      this.product = new ProductMaster();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['indooroutdoor'] && params['categoryid']) {
        this.indooroutdoor = params['indooroutdoor'];
        this.categoryid = params['categoryid'];
        this.categoryname = params['categoryname'];
        this.getMasterProductsByCategoryandIndoorOutdoor(this.categoryid,this.indooroutdoor);
        this.browseproductsheader = this.indooroutdoor + " " + this.categoryname.split('_').join(' ');
      } else if (params['searchstring']) {
        this.searchstring = params['searchstring'];
        this.getMasterProductsBySearchString(this.searchstring);
        this.browseproductsheader = "Search results: " + this.searchstring.split('_').join(' ');
      } else if (params['fabrictypeid']) {
        this.fabrictypeid = params['fabrictypeid'];
        this.fabrictype = params['fabrictype'];
        this.getMasterProductsByFabricTypeID(this.fabrictypeid);
        this.browseproductsheader = "Search results: " + this.fabrictype.split('_').join(' ');
      } else {
      }
  });
  }

  getMasterProductsBySearchString(searchstring:string): void {
    this.productService.getMasterProductsBySearchString(searchstring)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  }
  
  getMasterProductsByNewArrivals(): void {
    this.productService.getMasterProductsByNewArrivals()
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 

  getMasterProductsByCategoryandIndoorOutdoor(categoryid:number,indooroutdoor:string): void {
    this.productService.getMasterProductsByCategoryandIndoorOutdoor(categoryid,indooroutdoor)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 

  getMasterProductsByFabricTypeID(fabrictypeid:number): void {
    this.productService.getMasterProductsByFabricTypeID(fabrictypeid)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 
  
  
  goBack(): void {
    this.location.back();
  }
  
  ngOnDestroy() {
    // ...
  }

}
